import { Gift, SpecialKind } from "src/types/gift";
import useProfileById from "src/ui/hooks/useProfileById";

export const useGiftImageOverlayURL = (
  gift: Gift,
  senderId: null | string,
  receiverId: null | string
) => {
  let targetAccountId;
  switch (gift?.special) {
    case SpecialKind.AVATAR_GIFT:
      targetAccountId = senderId;
      break;
    case SpecialKind.STREAMER_AVATAR_GIFT:
      targetAccountId = receiverId;
      break;
    default:
      targetAccountId = "";
  }

  const { basicProfile } = useProfileById(targetAccountId, true);
  if (!basicProfile || !basicProfile.profilePictureUrl) {
    return null;
  }

  return basicProfile.profilePictureUrl;
};
