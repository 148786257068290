import React, {
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import classnames from "classnames";
import { emitEvent } from "@analytics/emit";
import { EventFields, EventNames } from "@analytics/enums";
import { bannerShownEventPersister } from "src/features/banners/utils/bannerShownEventPersister";
import { useIsCashierOrRefillOpen } from "src/features/offers/shared/exports";
import { ActionType, BannerConfig } from "src/types/banner";
import { Nullable, WithClasses } from "src/types/common";
import { ensureHttps } from "src/utils/ensureHttps";
import {
  addOpenFromWebParam,
  addTriggerIdParam,
} from "src/utils/locationUtils";
import {
  getAlternativeDomainContentSupportEnabled,
  getBannersExternalLinksEnabled,
} from "state/abTests";
import { bannerWebBreakpointArtworkMapping } from "ui/common/banner/constants";
import { useBreakpoint } from "ui/hooks/useBreakpoint";
import { useMakeAlternativeDomainUrl } from "ui/hooks/useMakeAlternativeDomainUrl";
import useUiAction from "ui/hooks/useUiAction";
import useDeepLinkActions from "ui/navigation/deepLink/useDeepLinkActions";
import styles from "./ConfiguredBanner.scss";

export interface ConfiguredBannerClasses {
  root: string;
}

const ConfiguredBanner: React.FC<
  WithClasses<
    { additionalAnalyticsParams: object; bannerConfig: BannerConfig },
    ConfiguredBannerClasses
  >
> = ({ bannerConfig, classes, additionalAnalyticsParams }) => {
  const history = useHistory();
  const breakpoint = useBreakpoint();
  const isNoneType = bannerConfig.action.actionType === ActionType.NONE;
  const isExternalUrlsEnabled = useSelector(getBannersExternalLinksEnabled);
  const [imageUrl, setImageUrl] = useState<Nullable<string>>(null);
  const makeAlternativeDomain = useMakeAlternativeDomainUrl(
    getAlternativeDomainContentSupportEnabled
  );

  const { isCashierOrRefill } = useIsCashierOrRefillOpen();

  useLayoutEffect(() => {
    const imageUrl =
      bannerConfig[bannerWebBreakpointArtworkMapping[breakpoint]] ??
      bannerConfig.imageUrl;

    setImageUrl(ensureHttps(makeAlternativeDomain(imageUrl)));
  }, [makeAlternativeDomain, bannerConfig, breakpoint]);

  const { extractDeepLinkAction, setTaskByTarget, setTaskByAdditionalAction } =
    useDeepLinkActions();

  const getBannerAction = useCallback(() => {
    if (isNoneType) {
      return null;
    }

    return extractDeepLinkAction(
      addOpenFromWebParam(
        addTriggerIdParam(
          new URL(bannerConfig.action.link),
          bannerConfig?.trackingId
        )
      ).toString()
    );
  }, [bannerConfig.action.link, extractDeepLinkAction, isNoneType]);

  const isHttpLikeProtocol = bannerConfig.action.link.startsWith("http");

  const hasToNavigateToPage =
    isExternalUrlsEnabled &&
    bannerConfig.action.actionType === ActionType.URL &&
    isHttpLikeProtocol;

  const handleBannerClick = useCallback(() => {
    const bannerAction = getBannerAction();

    if (bannerAction === null) {
      return;
    }

    if (bannerAction.additionalAction) {
      setTaskByAdditionalAction(bannerAction);
    } else if (bannerAction.target) {
      setTaskByTarget(bannerAction);
    } else {
      if (hasToNavigateToPage) {
        window.location.assign(bannerConfig.action.link);
      } else {
        history.push(bannerConfig.action.link);
      }
    }
  }, [
    getBannerAction,
    setTaskByAdditionalAction,
    setTaskByTarget,
    history,
    bannerConfig.action.link,
    hasToNavigateToPage,
  ]);

  const handleBannerClickAction = useUiAction({
    target: "banner_click",
    callback: handleBannerClick,
    additionalParams: {
      [EventFields.BANNER_TRACKING_ID]: bannerConfig.trackingId,
      [EventFields.ITEM_TYPE]: bannerConfig.name,
      [EventFields.ITEM_ID]: bannerConfig.id,
      [EventFields.SHARE_SOURCE_ID]: bannerConfig.displaySection.type,
      ...additionalAnalyticsParams,
    },
  });

  useEffect(() => {
    const isSent = bannerShownEventPersister.get(
      bannerConfig,
      isCashierOrRefill
    );

    if (isSent) {
      return;
    }

    bannerShownEventPersister.set(bannerConfig, isCashierOrRefill, true);

    emitEvent(EventNames.BANNER_SHOWN, {
      [EventFields.BANNER_TRACKING_ID]: bannerConfig.trackingId,
      [EventFields.ITEM_TYPE]: bannerConfig.name,
      [EventFields.ITEM_ID]: bannerConfig.id,
      [EventFields.SHARE_SOURCE_ID]: bannerConfig.displaySection.type,
      ...additionalAnalyticsParams,
    });
  }, [bannerConfig]);

  return (
    <div
      style={{
        backgroundImage: `url(${imageUrl})`,
      }}
      onClick={!isNoneType ? handleBannerClickAction : undefined}
      className={classnames(
        styles.root,
        styles[breakpoint],
        {
          [styles.noAction]: isNoneType,
        },
        classes?.root
      )}
      data-testid="configured-banner"
    />
  );
};

export default memo(ConfiguredBanner);
