import React, { forwardRef } from "react";
import classnames from "classnames";
import SimpleBar, { Props } from "simplebar-react";

import { keyboardEvents } from "src/enums";
import styles from "./Scrollbar.scss";
import "simplebar-react/dist/simplebar.min.css";

export enum ScrollbarVariant {
  LIGHT = "light",
  ON_SHADER = "onShader",
  THEMED = "themed",
}

interface ScrollbarProps extends Omit<Props, "classNames"> {
  className?: string;
  variant?: ScrollbarVariant;
  wrapperClassName?: string;
}

const Scrollbar = forwardRef<HTMLDivElement, ScrollbarProps>(
  (
    {
      className,
      children,
      wrapperClassName,
      autoHide = false,
      variant = ScrollbarVariant.THEMED,
      ...rest
    },
    ref
  ) => {
    const handleKeyDown = (event: React.KeyboardEvent) => {
      if (
        event.key === keyboardEvents.ARROW_DOWN ||
        event.key === keyboardEvents.ARROW_UP
      ) {
        event.preventDefault();
      }
    };

    return (
      <div className={classnames(styles.root, wrapperClassName)}>
        <SimpleBar
          className={classnames(
            styles.scrollContainer,
            styles[variant],
            className
          )}
          autoHide={autoHide}
          scrollableNodeProps={{
            ref,
            onKeyDown: handleKeyDown,
          }}
          {...rest}
        >
          {children}
        </SimpleBar>
      </div>
    );
  }
);

Scrollbar.displayName = "Scrollbar";

export default Scrollbar;
