import React, {
  MouseEvent,
  ReactNode,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import classnames from "classnames";
import { ComponentWithClassName, Nullable } from "src/types/common";
import styles from "./LongTapWrapper.scss";

interface LongTapWrapperProps {
  children: ReactNode;
  longTapThreshold?: number;
  onLongTap?: () => void;
  onLongTapStop?: () => void;
}

const MAX_LONG_TAP_DELTA = 5;

const LongTapWrapper: ComponentWithClassName<LongTapWrapperProps> = ({
  longTapThreshold = 500,
  onLongTap,
  onLongTapStop,
  children,
  className,
}) => {
  const [isLongTap, setIsLongTap] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);
  const [timer, setTimer] = useState<NodeJS.Timeout>();
  const ref = useRef<Nullable<HTMLDivElement>>(null);
  const startTouch = useRef<{ x: number; y: number } | null>(null);

  const handleTouchStart = useCallback(
    (event) => {
      startTouch.current = {
        x: event.touches[0].clientX,
        y: event.touches[0].clientY,
      };

      setIsScrolling(false);

      const newTimer = setTimeout(() => {
        setIsLongTap(true);
        onLongTap?.();
      }, longTapThreshold);

      setTimer(newTimer);
    },
    [longTapThreshold, onLongTap]
  );

  const handleMouseDown = useCallback(
    (event: MouseEvent) => {
      startTouch.current = {
        x: event.clientX,
        y: event.clientY,
      };

      setIsScrolling(false);

      const newTimer = setTimeout(() => {
        setIsLongTap(true);
        onLongTap?.();
      }, longTapThreshold);

      setTimer(newTimer);
    },
    [longTapThreshold, onLongTap]
  );

  const handleTouchMove = useCallback(
    (event) => {
      if (!startTouch.current) {
        return;
      }

      const deltaX = Math.abs(event.touches[0].clientX - startTouch.current.x);
      const deltaY = Math.abs(event.touches[0].clientY - startTouch.current.y);

      if (deltaX > MAX_LONG_TAP_DELTA || deltaY > MAX_LONG_TAP_DELTA) {
        setIsScrolling(true);
        clearTimeout(timer);
      }
    },
    [timer]
  );

  const handleMouseMove = useCallback(
    (event: MouseEvent) => {
      if (!startTouch.current) {
        return;
      }

      const deltaX = Math.abs(event.clientX - startTouch.current.x);
      const deltaY = Math.abs(event.clientY - startTouch.current.y);

      if (deltaX > MAX_LONG_TAP_DELTA || deltaY > MAX_LONG_TAP_DELTA) {
        setIsScrolling(true);
        clearTimeout(timer);
      }
    },
    [timer]
  );

  const handleTouchEnd = useCallback(() => {
    clearTimeout(timer);

    if (!isLongTap && !isScrolling) {
      onLongTapStop?.();
    }

    setIsLongTap(false);
  }, [isLongTap, isScrolling, onLongTapStop, timer]);

  const handleMouseUp = useCallback(() => {
    clearTimeout(timer);

    if (!isLongTap && !isScrolling) {
      onLongTapStop?.();
    }

    setIsLongTap(false);
  }, [isLongTap, isScrolling, onLongTapStop, timer]);

  const handleRightClick = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    return false;
  };

  useEffect(() => {
    const container = ref.current;

    container?.addEventListener("touchstart", handleTouchStart, {
      passive: false,
    });
    container?.addEventListener("touchmove", handleTouchMove, {
      passive: false,
    });

    return () => {
      container?.removeEventListener("touchstart", handleTouchStart);
      container?.removeEventListener("touchmove", handleTouchMove);
    };
  }, [handleTouchStart, handleTouchMove]);

  return (
    <div
      ref={ref}
      className={classnames(styles.root, className)}
      onContextMenu={handleRightClick}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onTouchEnd={handleTouchEnd}
    >
      {children}
    </div>
  );
};

export default memo(LongTapWrapper);
