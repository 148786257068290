import React, { memo, useCallback } from "react";
import { FormattedMessage, defineMessages } from "react-intl";
import { useSelector } from "react-redux";
import { Link, useRouteMatch } from "react-router-dom";
import classnames from "classnames";
import { Breakpoints } from "src/enums";
import { getIsInJosh } from "src/utils/joshUtils";
import { isStandalone } from "src/utils/locationUtils";
import { getConsentSettingsEnabled } from "state/abTests";
import { serverOwnedConfigSelectors } from "state/selectors";
import Button, { ButtonSize, ButtonVariant } from "ui/common/button/Button";
import EmojiSpan from "ui/common/emoji/EmojiSpan";
import Typography, { TYPOGRAPHY_TYPE } from "ui/common/typography/Typography";
import { useBreakpoint } from "ui/hooks/useBreakpoint";
import usePersistentState from "ui/hooks/usePersistentState";
import {
  linkToPrivacyPolicy,
  linkToWebviewApmOffers,
  linkToWebviewCheckout,
  linkToWebviewSafechargeOffers,
} from "ui/navigation/links";
import styles from "./CookiesDisclaimer.scss";

const breakpointToTypography = {
  [Breakpoints.SMALL_MOBILE]: TYPOGRAPHY_TYPE.PARAGRAPH5,
  [Breakpoints.MOBILE]: TYPOGRAPHY_TYPE.PARAGRAPH5,
  [Breakpoints.TABLET]: TYPOGRAPHY_TYPE.PARAGRAPH3,
  [Breakpoints.DESKTOP]: TYPOGRAPHY_TYPE.PARAGRAPH3,
};

const messages = defineMessages({
  text: {
    id: "cookies-disclaimer.text",
    defaultMessage:
      "Our website uses cookies to ensure you get the best experience on our website. {link}",
  },
  link: {
    id: "more-info",
    defaultMessage: "More info",
  },
});

const cookie = "🍪";
const CookiesDisclaimer: React.FC = () => {
  const [accepted, setAccepted] = usePersistentState({
    key: "acceptCookie",
    listenForUpdates: true,
    initialValue: false,
  });
  const breakpoint = useBreakpoint();
  const isConsentSettingsEnabled = useSelector(getConsentSettingsEnabled);
  const isServerConfigLoading = useSelector(
    serverOwnedConfigSelectors.getIsServerConfigLoading
  );
  const isMobileLayout = breakpoint !== Breakpoints.DESKTOP;
  const isOnRestrictedPages = useRouteMatch([
    linkToWebviewApmOffers,
    linkToWebviewSafechargeOffers,
    linkToWebviewCheckout,
  ]);

  const acceptCookie = useCallback(() => {
    setAccepted(true);
  }, [setAccepted]);

  if (
    accepted ||
    isStandalone ||
    isOnRestrictedPages ||
    isServerConfigLoading ||
    getIsInJosh() ||
    isConsentSettingsEnabled
  ) {
    return null;
  }

  return (
    <div className={classnames(styles.root, styles[breakpoint])}>
      <EmojiSpan className={styles.cookie}>{cookie}</EmojiSpan>
      <Typography
        type={breakpointToTypography[breakpoint]}
        className={styles.body}
      >
        <FormattedMessage
          {...messages.text}
          values={{
            link: isMobileLayout ? null : (
              <Typography
                to={linkToPrivacyPolicy}
                type={TYPOGRAPHY_TYPE.HEADLINE5}
                as={Link}
                className={styles.link}
                target="_blank"
              >
                <FormattedMessage {...messages.link} />
              </Typography>
            ),
          }}
        />
      </Typography>
      <Button
        size={
          breakpoint === Breakpoints.TABLET
            ? ButtonSize.BIG_48
            : ButtonSize.MEDIUM_32
        }
        variant={ButtonVariant.PRIMARY}
        onClick={acceptCookie}
        className={styles.button}
        data-testid="accept-cookies"
      >
        <FormattedMessage id="cookies-disclaimer.cta" defaultMessage="Accept" />
      </Button>
    </div>
  );
};

export default memo(CookiesDisclaimer);
