import React, { forwardRef, useCallback } from "react";
import { useSelector } from "react-redux";
import classnames from "classnames";
import emptyFunction from "fbjs/lib/emptyFunction";
import PropTypes from "prop-types";
import { getGiftsAlternativeDomainContentSupportEnabled } from "state/abTests";
import { giftsCacheSelectors } from "state/selectors";
import { useBreakpointMobileLayout } from "ui/hooks/useBreakpoint";
import { useMakeAlternativeDomainUrl } from "ui/hooks/useMakeAlternativeDomainUrl";
import styles from "./GiftTabs.scss";

const Tab = forwardRef(({ id, selectCategory, selected, tabIndex }, ref) => {
  const { name, icon, activeIcon } = useSelector(
    (state) => giftsCacheSelectors.getCategoryById(state, id) || {}
  );

  const updateCategory = useCallback(() => {
    selectCategory(id);
  }, [id, selectCategory]);

  const initTab = useCallback((e) => {
    ref.current.push(e);
  }, []);

  const makeAlternativeDomainUrl = useMakeAlternativeDomainUrl(
    getGiftsAlternativeDomainContentSupportEnabled
  );

  return (
    <div
      ref={initTab}
      data-testid={id}
      data-selected={selected}
      data-tabcategory={id}
      data-tabindex={tabIndex}
      onClick={updateCategory}
      className={classnames(styles.tab, {
        [styles.selected]: selected,
      })}
      tabIndex={0}
      role="button"
      onTouchStart={emptyFunction}
    >
      <div className={styles.iconContainer}>
        {activeIcon && (
          <div className={classnames(styles.maskedIcon, styles.activeIcon)}>
            <img src={makeAlternativeDomainUrl(activeIcon)} alt={name} />
          </div>
        )}
        <div
          className={classnames(
            styles.maskedIcon,
            activeIcon && selected && styles.hidden
          )}
        >
          <img src={makeAlternativeDomainUrl(icon)} alt={name} />
        </div>
      </div>
    </div>
  );
});

Tab.displayName = "Tab";
Tab.propTypes = {
  id: PropTypes.string.isRequired,
  selectCategory: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  tabIndex: PropTypes.number,
};

const GiftTabs = forwardRef(
  ({ categoryIdsList, selectCategory, selectedCategoryId }, ref) => {
    const isMobile = useBreakpointMobileLayout();

    return (
      <div
        data-testid="tabs"
        className={classnames(styles.root, isMobile && styles.mobile)}
      >
        {categoryIdsList.map((x, i) => (
          <Tab
            ref={ref}
            key={x}
            id={x}
            selected={selectedCategoryId === x}
            selectCategory={selectCategory}
            tabIndex={i}
          />
        ))}
      </div>
    );
  }
);

GiftTabs.propTypes = {
  categoryIdsList: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectCategory: PropTypes.func.isRequired,
  selectedCategoryId: PropTypes.string,
};

GiftTabs.displayName = "GiftTabs";

export default GiftTabs;
