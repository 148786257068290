import React, { memo, useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import classnames from "classnames";
import { getLottieAlternativeDomainEnabled } from "state/abTests";
import { userSelectors } from "state/selectors";
import { FormattedNumber } from "ui/common/Formatted";
import LottieAnimation from "ui/common/lottie/LottieAnimation";
import Typography, { TYPOGRAPHY_TYPE } from "ui/common/typography/Typography";
import { AllTypographyTypes } from "ui/common/typography/types";
import useAnimatedCoins from "ui/hooks/useAnimatedCoins";
import { useMakeAlternativeDomainUrl } from "ui/hooks/useMakeAlternativeDomainUrl";
import { ReactComponent as CoinIcon } from "img/ic_coin.svg";
import styles from "./CoinsView.scss";

const lottieConfigCoins = {
  path: "https://resources.tango.me/lottie_animations/coins/data.json",
  loop: false,
  renderer: "canvas",
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
  autoplay: true,
} as const;

interface CoinsViewProps {
  className?: string;
  typographyType?: AllTypographyTypes;
}

const CoinsView: React.FC<CoinsViewProps> = ({ className, typographyType }) => {
  const coinsBalance = useSelector(userSelectors.getCoinsBalance);
  const makeAlternativeDomainUrl = useMakeAlternativeDomainUrl(
    getLottieAlternativeDomainEnabled
  );
  const changedLottieConfig = useMemo(
    () => ({
      ...lottieConfigCoins,
      path: makeAlternativeDomainUrl(lottieConfigCoins.path),
    }),
    []
  );

  const [showAnimation, setShowAnimation] = useState(false);
  const handleStart = useCallback(() => {
    setShowAnimation(true);
  }, [setShowAnimation]);
  const handleLottieComplete = useCallback(() => {
    setShowAnimation(false);
  }, [setShowAnimation]);
  const coins = useAnimatedCoins(coinsBalance, true, {
    onStart: handleStart,
    animateOnlyIncrease: true,
  });

  return (
    <Typography
      type={typographyType ?? TYPOGRAPHY_TYPE.HEADLINE5}
      className={classnames(styles.root, className)}
      data-testid="coins-balance"
      as="div"
    >
      {showAnimation && (
        <LottieAnimation
          lottieConfig={changedLottieConfig}
          className={styles.lottieCoins}
          onComplete={handleLottieComplete}
        />
      )}
      <CoinIcon className={styles.coin} />
      <span>
        <FormattedNumber value={coins} />
      </span>
    </Typography>
  );
};

export default memo(CoinsView);
