// TODO: on baseline web.explore.navigation.enabled
//  reuse isoCodeToName const in LanguagePicker on settings page
// https://tango-me.atlassian.net/browse/WEB-6018
export const isoCodeToName: { [key: string]: string } = {
  en: "English",
  ar: "العربية",
  nl: "Nederlands",
  fil: "Filipino",
  fr: "Français",
  de: "Deutsch",
  he: "עִבְרִית",
  hi: "हिन्दी",
  id: "Bahasa Indonesia",
  it: "Italiano",
  ja: "日本語",
  ko: "한국어",
  ms: "Bahasa Melayu",
  pt: "Português",
  ro: "Română",
  ru: "Русский",
  es: "Español",
  ta: "தமிழ்",
  te: "తెలుగు",
  th: "ภาษาไทย",
  tr: "Türkçe",
  uk: "Українська",
  vi: "Tiếng Việt",
  sv: "Svenska",
};
