import React, { memo, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { DAY, HOUR, MINUTE, SECOND } from "src/constants";

interface TimeLeftProps {
  duration: number;
}

const TimeLeft: React.FC<TimeLeftProps> = ({ duration }) => {
  const { days, hours, minutes, seconds } = useMemo(() => {
    const days = Math.floor(duration / DAY);
    const hours = Math.floor((duration - days * DAY) / HOUR);
    const minutes = Math.floor((duration - days * DAY - hours * HOUR) / MINUTE);
    const seconds = Math.floor(duration / SECOND) % 60;

    return {
      days,
      hours,
      minutes,
      seconds,
    };
  }, [duration]);

  return (
    <>
      {days > 0 && (
        <>
          <FormattedMessage
            id="guardian.duration.days"
            defaultMessage="{days} day(s)"
            values={{ days }}
          />
          &nbsp;
        </>
      )}
      {hours > 0 && (
        <>
          <FormattedMessage
            id="guardian.duration.hours"
            defaultMessage="{hours} hour(s)"
            values={{ hours }}
          />
          &nbsp;
        </>
      )}
      {minutes > 0 && (
        <>
          <FormattedMessage
            id="guardian.duration.minutes"
            defaultMessage="{minutes} minute(s)"
            values={{ minutes }}
          />
          &nbsp;
        </>
      )}
      {seconds > 0 && (
        <FormattedMessage
          id="guardian.duration.seconds"
          defaultMessage="{seconds} second(s)"
          values={{ seconds }}
        />
      )}
    </>
  );
};

export default memo(TimeLeft);
