import React, { MouseEvent, memo, useCallback, useState } from "react";
import classnames from "classnames";
import { HTMLButtonProps } from "src/types/common";
import useExclusiveClickHandler from "ui/hooks/useExclusiveClickHandler";
import styles from "./Switch.scss";

type SwitchEventHandler = (
  checked: boolean,
  event: MouseEvent<HTMLButtonElement>
) => void;

interface SwitchProps
  extends Omit<HTMLButtonProps, "onChange" | "onClick" | "role"> {
  checked?: boolean;
  defaultChecked?: boolean;
  isGlobalMode?: boolean;
  onChange?: SwitchEventHandler;
  onClick?: SwitchEventHandler;
}

const Switch: React.FC<SwitchProps> = ({
  className,
  defaultChecked = false,
  checked,
  onClick,
  onChange,
  isGlobalMode,
  ...rest
}) => {
  const [innerChecked, setChecked] = useState(
    checked !== undefined ? checked : defaultChecked
  );
  const toggle = useCallback(
    (newChecked: boolean, event: MouseEvent<HTMLButtonElement>) => {
      setChecked(newChecked);
      onChange?.(newChecked, event);

      return newChecked;
    },
    [onChange]
  );
  const handleClick = useExclusiveClickHandler<MouseEvent<HTMLButtonElement>>(
    (event) => {
      event.stopPropagation();
      const newChecked = toggle(!innerChecked, event);
      onClick?.(newChecked, event);
    }
  );

  if (checked !== undefined && checked !== innerChecked) {
    setChecked(checked);
  }

  return (
    <button
      className={classnames(
        styles.root,
        innerChecked && styles.checked,
        isGlobalMode && styles.globalMode,
        className
      )}
      role="switch"
      onClick={handleClick}
      {...rest}
      aria-checked={innerChecked}
    >
      <div className={styles.handle} />
    </button>
  );
};

export default memo(Switch);
