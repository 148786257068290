import React, { memo, useMemo } from "react";
import { shallowEqual, useSelector } from "react-redux";
import classnames from "classnames";
import { Breakpoints } from "src/enums";
import { RootState } from "src/state/delegate";
import {
  streamsCacheSelectors,
  subscriptionPerStreamerListSelectors,
  viewerSessionSelectors,
} from "state/selectors";
import { RoundedNumber } from "ui/common/Formatted";
import Typography, { TYPOGRAPHY_TYPE } from "ui/common/typography/Typography";
import { useBreakpointPrecise } from "ui/hooks/useBreakpoint";
import useMyProfile from "ui/hooks/useMyProfile";
import { ReactComponent as DiamondIcon12 } from "img/ic_diamond_12.svg";
import { ReactComponent as DiamondIcon16 } from "img/ic_diamond_16.svg";
import { ReactComponent as EyeIcon16 } from "img/ic_eye_16.svg";
import { ReactComponent as StarIcon12 } from "img/ic_star_12.svg";
import { ReactComponent as StarIcon16 } from "img/ic_star_16.svg";
import { ReactComponent as UsersIcon12 } from "img/ic_users_12.svg";
import { ReactComponent as UsersIcon16 } from "img/ic_users_16.svg";
import styles from "./StreamStats.scss";

interface CustomizableStreamStats {
  broadcastId?: string;
  className?: string;
  compact?: boolean;
  light?: boolean;
  rounded?: boolean;
  showOnlyEarnedDiamonds?: false;
  showPoints?: boolean;
  showViewers?: boolean;
}

interface OnlyEarnedDiamondsStreamStats {
  broadcastId?: undefined;
  className?: string;
  compact?: boolean;
  light?: boolean;
  rounded?: boolean;
  showOnlyEarnedDiamonds: true;
  showPoints?: undefined;
  showViewers?: undefined;
}

type StreamStatsProps = CustomizableStreamStats | OnlyEarnedDiamondsStreamStats;

const StreamStats: React.FC<StreamStatsProps> = ({
  broadcastId,
  className = "",
  compact = false,
  light = false,
  rounded = false,
  showOnlyEarnedDiamonds = false,
  showPoints = true,
  showViewers = false,
}) => {
  const breakpoint = useBreakpointPrecise();
  const typographyType = useMemo(() => {
    if (rounded) {
      return compact ? TYPOGRAPHY_TYPE.MINI : TYPOGRAPHY_TYPE.HEADLINE6;
    }

    return breakpoint === Breakpoints.TABLET
      ? TYPOGRAPHY_TYPE.HEADLINE5
      : TYPOGRAPHY_TYPE.HEADLINE6;
  }, [breakpoint, rounded, compact]);
  const { followStats, liveStats } = useMyProfile(true);
  const stream = useSelector(
    (state) => streamsCacheSelectors.getStreamById(state, broadcastId),
    shallowEqual
  );
  const broadcasterId = useSelector(viewerSessionSelectors.getBroadcasterId);
  const subscribersCount =
    useSelector(
      (state: RootState) =>
        subscriptionPerStreamerListSelectors.getStreamerSubscribersIds(
          state,
          broadcasterId
        ),
      shallowEqual
    )?.length || 0;

  const totalStreamViewers = useSelector(
    viewerSessionSelectors.getUniqueViewersCount
  );

  const broadcastPoints = useSelector(
    viewerSessionSelectors.getPointsGiftedInStream
  );

  const customizableStreamStats = useMemo(
    () => (
      <>
        {showViewers && (
          <div className={styles.stat}>
            <EyeIcon16 className={styles.icon} />
            <RoundedNumber value={totalStreamViewers || 0} />
          </div>
        )}
        {showPoints && (
          <div className={styles.stat} data-testid="diamonds-number">
            {compact ? (
              <DiamondIcon12 className={styles.icon} />
            ) : (
              <DiamondIcon16 className={styles.icon} />
            )}
            <span>
              {showViewers && broadcastPoints > 0 && "+"}
              <RoundedNumber
                value={showViewers ? broadcastPoints : liveStats?.points || 0}
              />
            </span>
          </div>
        )}
        {!showViewers && (
          <>
            {followStats?.followersCount > 0 && (
              <div className={styles.stat} data-testid="followers-number">
                {compact ? (
                  <UsersIcon12 className={styles.icon} />
                ) : (
                  <UsersIcon16 className={styles.icon} />
                )}
                <RoundedNumber value={followStats.followersCount} />
              </div>
            )}
            {subscribersCount > 0 && (
              <div className={styles.stat} data-testid="subscribers-number">
                {compact ? (
                  <StarIcon12 className={styles.icon} />
                ) : (
                  <StarIcon16 className={styles.icon} />
                )}
                <RoundedNumber value={subscribersCount} />
              </div>
            )}
          </>
        )}
      </>
    ),
    [
      broadcastPoints,
      compact,
      followStats?.followersCount,
      liveStats?.points,
      showPoints,
      showViewers,
      stream?.viewerCount,
      subscribersCount,
    ]
  );

  const onlyEarnedDiamondsStreamStats = useMemo(
    () => (
      <div className={styles.stat} data-testid="diamonds-number">
        {compact ? (
          <DiamondIcon12 className={styles.icon} />
        ) : (
          <DiamondIcon16 className={styles.icon} />
        )}
        <span>
          {broadcastPoints > 0 && "+"}
          <RoundedNumber value={broadcastPoints} />
        </span>
      </div>
    ),
    [broadcastPoints, compact]
  );

  return (
    <Typography
      className={classnames(
        styles.root,
        styles[breakpoint],
        {
          [styles.rounded]: rounded,
          [styles.light]: light,
          [styles.compact]: compact,
        },
        className
      )}
      type={typographyType}
      as="div"
    >
      {showOnlyEarnedDiamonds
        ? onlyEarnedDiamondsStreamStats
        : customizableStreamStats}
    </Typography>
  );
};

export default memo(StreamStats);
