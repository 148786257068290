import React, { memo, useCallback, useMemo, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import { emitUiAction } from "@analytics/emitUiAction";
import { ComponentWithClassName } from "src/types/common";
import { getLottieAlternativeDomainEnabled } from "state/abTests";
import { RootState } from "state/delegate";
import { giftsCacheSelectors, userSelectors } from "state/selectors";
import { actionCreators } from "state/tree/giftsCache";
import { FormattedNumber } from "ui/common/Formatted";
import LottieAnimation from "ui/common/lottie/LottieAnimation";
import Typography, { TYPOGRAPHY_TYPE } from "ui/common/typography/Typography";
import useAnimatedCoins from "ui/hooks/useAnimatedCoins";
import { useMakeAlternativeDomainUrl } from "ui/hooks/useMakeAlternativeDomainUrl";
import { ReactComponent as CoinIcon } from "img/ic_coin.svg";
import { ReactComponent as DiamondIcon16 } from "img/ic_diamond_16.svg";
import styles from "./CoinsViewTab.scss";

const lottieConfigCoins = {
  path: "https://resources.tango.me/lottie_animations/coins/data.json",
  loop: false,
  renderer: "canvas",
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
  autoplay: true,
} as const;

const selector = (state: RootState) => ({
  coinsBalance: userSelectors.getCoinsBalance(state),
  pointsBalance: userSelectors.getPointsBalance(state),
  isBuyWithCoins: giftsCacheSelectors.isBuyGiftWithCoins(state),
});

enum TabType {
  COINS = "coins",
  DIAMONDS = "diamond",
}

const tabs = [
  { type: TabType.COINS, icon: <CoinIcon className={styles.coin} /> },
  { type: TabType.DIAMONDS, icon: <DiamondIcon16 /> },
];

const CoinsViewTab: ComponentWithClassName = ({ className }) => {
  const dispatch = useDispatch();
  const { coinsBalance, pointsBalance, isBuyWithCoins } = useSelector(
    selector,
    shallowEqual
  );
  const [showAnimation, setShowAnimation] = useState(false);
  const handleStart = useCallback(() => {
    setShowAnimation(true);
  }, [setShowAnimation]);
  const handleLottieComplete = useCallback(() => {
    setShowAnimation(false);
  }, [setShowAnimation]);
  const coins = useAnimatedCoins(coinsBalance, true, {
    onStart: handleStart,
    animateOnlyIncrease: true,
  });

  const makeAlternativeDomainUrl = useMakeAlternativeDomainUrl(
    getLottieAlternativeDomainEnabled
  );
  const changedLottieConfig = useMemo(
    () => ({
      ...lottieConfigCoins,
      path: makeAlternativeDomainUrl(lottieConfigCoins.path),
    }),
    []
  );

  const userBalance = {
    [TabType.COINS]: coins,
    [TabType.DIAMONDS]: pointsBalance,
  };

  const toggleSwitchCoins = useCallback(() => {
    dispatch(actionCreators.changeTypeBuyGift());

    emitUiAction({
      tango_screen: {
        reportedName: "gift_drawer",
      },
      target: isBuyWithCoins ? "switch_to_diamonds" : "switch_to_coins",
    });
  }, [isBuyWithCoins, dispatch]);

  return (
    <Typography
      type={TYPOGRAPHY_TYPE.HEADLINE5}
      className={classnames(className, styles.root)}
      as="div"
    >
      {tabs.map(({ type, icon }) => (
        <div
          className={classnames(
            styles.item,
            (isBuyWithCoins
              ? type === TabType.COINS
              : type === TabType.DIAMONDS) && styles.active
          )}
          key={type}
          onClick={toggleSwitchCoins}
          role="button"
          tabIndex={0}
        >
          {showAnimation && type === TabType.COINS && (
            <LottieAnimation
              lottieConfig={changedLottieConfig}
              className={styles.lottieCoins}
              onComplete={handleLottieComplete}
            />
          )}
          {icon}
          <span className={styles.amount}>
            <FormattedNumber value={userBalance[type]} />
          </span>
        </div>
      ))}
    </Typography>
  );
};

export default memo(CoinsViewTab);
