import React, { FC, InputHTMLAttributes, RefObject } from "react";
import TextareaAutosize, {
  TextareaAutosizeProps,
} from "react-textarea-autosize";
import classnames from "classnames";
import { Field, FieldProps } from "ui/common/textField/Field";
import styles from "./TextArea.scss";

type TextAreaProps = {
  inputClassName?: string;
  inputRef?: RefObject<HTMLTextAreaElement>;
} & FieldProps &
  InputHTMLAttributes<HTMLTextAreaElement> &
  TextareaAutosizeProps;

export const TextArea: FC<TextAreaProps> = (props) => {
  const { inputRef, inputClassName, inputWrapperClassName, ...rest } = props;

  return (
    <Field
      inputWrapperClassName={classnames(
        styles.textAreaWrapper,
        inputWrapperClassName
      )}
      {...rest}
    >
      <TextareaAutosize
        {...rest}
        ref={inputRef}
        className={classnames(styles.textArea, inputClassName)}
      />
    </Field>
  );
};
