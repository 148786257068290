import React, { memo } from "react";
import { useSelector } from "react-redux";
import classnames from "classnames";
import { GOOGLE_PLAY_LINK } from "src/constants";
import { DeviceType } from "src/enums";
import { HTMLAnchorProps } from "src/types/common";
import getAppDeeplink from "src/utils/getAppDeeplink";
import { deviceInfoSelectors } from "state/selectors";
import styles from "./StoreLink.scss";

interface BaseStoreLinkProps extends HTMLAnchorProps {
  dataTestId: string;
}

interface StoreLinkProps
  extends Omit<BaseStoreLinkProps, "dataTestId" | "href"> {
  useDarkIcon?: boolean;
}

const StoreLink: React.FC<BaseStoreLinkProps> = ({
  className,
  href,
  dataTestId,
  children,
  ...rest
}) => {
  const deviceType = useSelector(deviceInfoSelectors.getDeviceType);

  return (
    <a
      target="_blank"
      rel="noreferrer noopener"
      href={
        deviceType === DeviceType.DESKTOP ? href : getAppDeeplink(deviceType)
      }
      className={className}
      data-testid={dataTestId}
      {...rest}
    />
  );
};

export const GooglePlayLink = memo<StoreLinkProps>(
  ({ useDarkIcon = true, className, ...rest }) => (
    <StoreLink
      className={classnames(
        className,
        styles.root,
        useDarkIcon ? styles.googleDark : styles.googleLight
      )}
      href={GOOGLE_PLAY_LINK}
      dataTestId="google-play-link"
      {...rest}
    />
  )
);

GooglePlayLink.displayName = "GooglePlayLink";
