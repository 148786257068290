import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { datadogLogs } from "@datadog/browser-logs";
import classnames from "classnames";
import { SECOND } from "src/constants";
import { ComponentWithClassName, VoidCallback } from "src/types/common";
import { Gift } from "src/types/gift";
import { MediaGift } from "src/types/richFragment/RichGiftEvent";
import GiftAnimationFactory, {
  GiftAnimationProps,
} from "src/ui/animations/GiftAnimationFactory";
import {
  getGiftAnimationMetricsEnabled,
  getGiftsAlternativeDomainContentSupportEnabled,
  getMediaGiftGifDuration,
} from "state/abTests";
import { useMakeAlternativeDomainUrl } from "ui/hooks/useMakeAlternativeDomainUrl";
import {
  ANIMATION_LABEL,
  MAX_ANIMATION_DURATION,
  MIN_ANIMATION_DURATION,
} from "./constants";
import styles from "./GiftOverlay.scss";

export interface GiftOverlayProps {
  components?: GiftAnimationProps["components"];
  gift?: Gift;
  hideAnimationDurationMs?: number;
  isDisableAnimationMetrics?: boolean;
  mediaGift?: MediaGift;
  onComplete?: VoidCallback;
  onHideComplete?: VoidCallback;
}

const GiftOverlay: ComponentWithClassName<GiftOverlayProps> = ({
  gift,
  onComplete,
  hideAnimationDurationMs = SECOND,
  onHideComplete,
  mediaGift = {},
  components,
  className,
  isDisableAnimationMetrics = false,
}) => {
  const [isHidden, setHidden] = useState(false);
  const animationStartTimeRef = useRef<null | number>(null);
  const animationTimeoutRef = useRef<null | number>(null);

  const gifDuration = useSelector(getMediaGiftGifDuration);
  const isGiftAnimationMetricsSoc = useSelector(getGiftAnimationMetricsEnabled);
  const isGiftAnimationMetricsEnabled =
    !isDisableAnimationMetrics && isGiftAnimationMetricsSoc;

  const containerStyle = useMemo(
    () =>
      ({
        "--translateX": "0",
        "--translateY": "0",
        "--animDuration": `${hideAnimationDurationMs / SECOND}s`,
      }) as React.CSSProperties,
    [hideAnimationDurationMs]
  );

  const handleFailed = useCallback(() => {
    if (isGiftAnimationMetricsEnabled && gift) {
      datadogLogs.logger.warn(ANIMATION_LABEL, {
        gift: {
          id: gift.id,
          name: gift.name,
          special: gift.special,
          success: false,
        },
      });
    }
  }, [isGiftAnimationMetricsEnabled, gift]);

  const setAnimationFailedTimeout = useCallback(() => {
    animationTimeoutRef.current = window.setTimeout(() => {
      handleFailed();
      animationTimeoutRef.current = null;
    }, MAX_ANIMATION_DURATION);
  }, [handleFailed]);

  const clearAnimationTimeout = useCallback(() => {
    if (animationTimeoutRef.current) {
      clearTimeout(animationTimeoutRef.current);
      animationTimeoutRef.current = null;
    }
  }, []);

  const handleComplete = useCallback(() => {
    setHidden(true);

    if (isGiftAnimationMetricsEnabled && gift) {
      const startTime = animationStartTimeRef.current;
      const animationDuration = startTime
        ? Math.floor(performance.now() - startTime)
        : null;

      clearAnimationTimeout();

      if (animationDuration && animationDuration < MIN_ANIMATION_DURATION) {
        datadogLogs.logger.warn(ANIMATION_LABEL, {
          gift: {
            id: gift.id,
            name: gift.name,
            special: gift.special,
            success: false,
          },
        });
      } else {
        datadogLogs.logger.info(ANIMATION_LABEL, {
          gift: {
            id: gift.id,
            name: gift.name,
            special: gift.special,
            success: true,
          },
        });
      }
    }

    onComplete?.();
  }, [onComplete, isGiftAnimationMetricsEnabled, gift, clearAnimationTimeout]);

  useEffect(() => {
    if (isGiftAnimationMetricsEnabled && gift) {
      animationStartTimeRef.current = performance.now();
      setAnimationFailedTimeout();

      return () => {
        clearAnimationTimeout();
      };
    }
  }, [
    isGiftAnimationMetricsEnabled,
    gift,
    handleFailed,
    setAnimationFailedTimeout,
    clearAnimationTimeout,
  ]);

  useEffect(() => {
    if (isHidden) {
      setHidden(false);
    }
  }, [gift]);

  useEffect(() => {
    if (!mediaGift.webpUrl) {
      return;
    }
    const durationMs = gifDuration * SECOND;
    const timerId = setTimeout(() => {
      handleComplete();
    }, durationMs);

    return () => {
      clearTimeout(timerId);
    };
  }, []);

  const makeAlternativeDomainUrl = useMakeAlternativeDomainUrl(
    getGiftsAlternativeDomainContentSupportEnabled
  );

  return (
    <div
      className={classnames([styles.root, className])}
      data-testid="gift-overlay"
    >
      <div
        className={classnames(styles.hideAnimationContainer, {
          active: isHidden,
        })}
        style={containerStyle}
        onAnimationEnd={onHideComplete}
      >
        {gift && (
          <GiftAnimationFactory
            gift={gift}
            onComplete={handleComplete}
            onFailed={handleFailed}
            components={components}
          />
        )}

        {mediaGift.webpUrl && window.Modernizr.webpanimation && (
          <img
            src={makeAlternativeDomainUrl(mediaGift.webpUrl)}
            className={styles.gif}
          />
        )}
      </div>
    </div>
  );
};

export default GiftOverlay;
