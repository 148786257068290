import React, {
  ImgHTMLAttributes,
  ReactElement,
  memo,
  useRef,
  useState,
} from "react";
import { useLazyLoadImage } from "ui/hooks/useLazyLoadImage";
import { ReactComponent as GiftPlaceholder } from "img/gift-placeholder.svg";
import style from "./LazyImage.scss";

interface LazyImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  containerClassName?: string;
}

const LazyImage = ({
  containerClassName,
  ...props
}: LazyImageProps): ReactElement => {
  const [isInView, setIsInView] = useState(false);
  const imgRef = useRef<HTMLDivElement>(null);
  useLazyLoadImage(imgRef, () => {
    setIsInView(true);
  });

  return (
    <div ref={imgRef} className={containerClassName}>
      {isInView ? (
        <img {...props} />
      ) : (
        <GiftPlaceholder className={style.icon} />
      )}
    </div>
  );
};

export default memo(LazyImage);
