import React, {
  CSSProperties,
  MouseEvent,
  MutableRefObject,
  memo,
  useEffect,
  useRef,
} from "react";
import { FormattedMessage, defineMessages } from "react-intl";
import { NavLink } from "react-router-dom";
import classnames from "classnames";
import emptyFunction from "fbjs/lib/emptyFunction";
import { LiveFeedType } from "src/enums";
import { Nullable } from "src/types/common";
import { getAlternativeDomainContentSupportEnabled } from "state/abTests";
import Typography, { TYPOGRAPHY_TYPE } from "ui/common/typography/Typography";
import { useMakeAlternativeDomainUrl } from "ui/hooks/useMakeAlternativeDomainUrl";
import { TabToRenderConfig } from "ui/scenes/live/shared/types";
import styles from "ui/common/tabs/ExploreRadioChipTabs/ExploreTab.scss";

export const messages: Record<string, { defaultMessage: string; id: string }> =
  defineMessages({
    [LiveFeedType.NEARBY]: {
      id: "live_tabs_nearby",
      defaultMessage: "Nearby",
    },
    obs: {
      id: "live_tabs_obs",
      defaultMessage: "Gamers",
    },
    [LiveFeedType.POPULAR]: {
      id: "live_tabs_popular",
      defaultMessage: "Popular",
    },
    [LiveFeedType.NEW]: {
      id: "live_tabs_new",
      defaultMessage: "New",
    },
    [LiveFeedType.RECOMMENDED]: {
      id: "live_tabs_recommended",
      defaultMessage: "For You",
    },
    [LiveFeedType.CREATORS]: {
      id: "live_tabs_creators",
      defaultMessage: "Artists",
    },
    rising_stars: {
      id: "live_tabs_rising_stars",
      defaultMessage: "Rising Stars",
    },
    battle: {
      id: "battle",
      defaultMessage: "Battle",
    },
    social: {
      id: "live_tabs_social",
      defaultMessage: "Social",
    },
  });

interface ExploreTabProps {
  className?: string;
  config: TabToRenderConfig;
  isActive: boolean;
  isMobile?: boolean;
  onActive: (ref: MutableRefObject<Nullable<HTMLAnchorElement>>) => void;
  onClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
}

const ExploreTab = memo<ExploreTabProps>(
  ({ className, config, isActive, onClick, onActive, isMobile }) => {
    const tabRef = useRef<Nullable<HTMLAnchorElement>>(null);

    const { id, icon, to, title, dataTestId } = config;

    const makeAlternativeDomain = useMakeAlternativeDomainUrl(
      getAlternativeDomainContentSupportEnabled
    );

    useEffect(() => {
      if (isActive) {
        onActive(tabRef);
      }
    }, [isActive]);

    return (
      <NavLink
        ref={tabRef}
        className={classnames(styles.link, className, {
          [styles.active]: isActive,
          [styles.iconPadding]: !!icon && isActive,
        })}
        to={to}
        onClick={onClick}
        data-testid={dataTestId}
        id={id}
        onTouchStart={emptyFunction}
      >
        <Typography
          className={styles.container}
          type={
            isMobile ? TYPOGRAPHY_TYPE.HEADLINE5 : TYPOGRAPHY_TYPE.HEADLINE4
          }
        >
          <div
            className={styles.icon}
            style={
              {
                "--mask-url": `url(${makeAlternativeDomain(icon)}) center/contain`,
              } as CSSProperties
            }
          />

          {messages[id] ? <FormattedMessage {...messages[id]} /> : title}
        </Typography>
      </NavLink>
    );
  }
);

ExploreTab.displayName = "ChipTab";

export default ExploreTab;
