import React, { memo } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import classnames from "classnames";
import {
  SETTINGS_SCREEN_APP,
  SETTINGS_SCREEN_PAYMENT,
} from "enums/settingsScreenType";
import { Breakpoints } from "src/enums";
import { RedeemsMenuItem } from "src/features/finance/redeems/exports/RedeemsMenuItem";
import { getIsGifterStatisticsPageEnabled } from "src/features/gifterStatistics/soc/gifterStatisticsSoc";
import { LoyaltyProgramMenuItem } from "src/features/loyaltyProgram/exports/components";
import {
  LegalLinks,
  LockIcon,
  UserIcon,
} from "src/features/settings/imports/ui";
import {
  SocialGamesAnalyticEventName,
  SocialGamesScreenName,
  SocialGamesTargetType,
} from "src/features/socialGames/exports/common";
import useSocialGamesMenuItemEnabled from "src/features/socialGames/hooks/useSocialGamesMenuItemEnabled";
import {
  getIsDropdownMenuLoyaltyProgramEnabled,
  getIsNewSettingsPageEnabled,
  getIsRedesignedReferralProgramEnabled,
  getSubscribeMenuItemEnabled,
  isLocalSubscriptionsEnabled,
} from "state/abTests";
import DropdownListItem from "ui/common/dropdownMenu/menuList/components/DropdownListItem";
import ExploreNavigationDarkModeMenuItem from "ui/common/dropdownMenu/menuList/components/ExploreNavigationDarkModeMenuItem";
import sharedMessages from "ui/common/intl/sharedMessages";
import Typography from "ui/common/typography/Typography";
import { TYPOGRAPHY_TYPE } from "ui/common/typography/types";
import { useBreakpointPrecise } from "ui/hooks/useBreakpoint";
import useUiAction from "ui/hooks/useUiAction";
import {
  linkToAccountSettingsPage,
  linkToGeneralSettingsPage,
  linkToGifterStatistics,
  linkToNFTAuction,
  linkToPrivacySettingsPage,
  linkToProfileSettingsPage,
  linkToReferralProgram,
  linkToSocialGamesBase,
  makeLinkToSettingsScreen,
} from "ui/navigation/links";
import useAuctionEnabled from "ui/nft/hooks/useAuctionEnabled";
import FanZoneItem from "./FanZoneItem";
import MyFansItem from "./MyFansItem";
import { ReactComponent as PaymentSettingsIcon } from "img/new-ui/burger_menu/ic-payment-settings.svg";
import { ReactComponent as ReferralIcon } from "img/new-ui/burger_menu/ic-referral-link.svg";
import { ReactComponent as SettingsIcon } from "img/new-ui/burger_menu/ic-settings.svg";
import { ReactComponent as GifterStatisticsIcon } from "img/new-ui/burger_menu/statistics.svg";
import { ReactComponent as NftAuctionIcon } from "img/user_menu_icons/nft_auction.svg";
import { ReactComponent as SocialGamesIcon } from "img/user_menu_icons/social-games.svg";
import styles from "ui/common/dropdownMenu/menuList/components/AppVersionMenuItem.scss";

interface AuthorizedUserItemsProps {
  isBurgerMenu: boolean;
}

const AuthorizedUserItems: React.FC<AuthorizedUserItemsProps> = ({
  isBurgerMenu,
}) => {
  const isAuctionEnabled = useAuctionEnabled();
  const isSocialGamesEnabled = useSocialGamesMenuItemEnabled();
  const isSubscriptionsEnabled = useSelector(isLocalSubscriptionsEnabled);
  const isSubscribeMenuItemEnabled = useSelector(getSubscribeMenuItemEnabled);
  const isNewSettingEnabled = useSelector(getIsNewSettingsPageEnabled);
  const isRedesignedReferralProgramEnabled = useSelector(
    getIsRedesignedReferralProgramEnabled
  );
  const isGifterStatisticsEnabled = useSelector(
    getIsGifterStatisticsPageEnabled
  );
  const isDropdownMenuLoyaltyProgramEnabled = useSelector(
    getIsDropdownMenuLoyaltyProgramEnabled
  );

  const reportGoNFTAuctionUiAction = useUiAction({
    target: "nft_auction",
  });

  const reportGoSocialGamesUiAction = useUiAction({
    [SocialGamesAnalyticEventName.TARGET]:
      SocialGamesTargetType.SOCIAL_GAMES_ENTER,
    additionalParams: {
      [SocialGamesAnalyticEventName.SCREEN_NAME]: SocialGamesScreenName.PROFILE,
    },
  });

  const breakpoint = useBreakpointPrecise();
  const isDesktop = breakpoint === Breakpoints.DESKTOP;

  return (
    <>
      <Typography
        type={TYPOGRAPHY_TYPE.PARAGRAPH3}
        className={classnames(styles.menuCategoryTitle, styles[breakpoint])}
        as="div"
      >
        <FormattedMessage {...sharedMessages.specialPrograms} />
      </Typography>
      {isRedesignedReferralProgramEnabled && (
        <DropdownListItem
          Icon={ReferralIcon}
          titleMessage={sharedMessages.agencyReferralProgram}
          data-testid="virality-button"
          to={{
            pathname: linkToReferralProgram,
            state: { source: "banner_profile" },
          }}
        />
      )}
      {isDropdownMenuLoyaltyProgramEnabled && <LoyaltyProgramMenuItem />}
      {isAuctionEnabled && (
        <DropdownListItem
          to={linkToNFTAuction}
          data-testid="nftAuction"
          onClick={reportGoNFTAuctionUiAction}
          Icon={NftAuctionIcon}
          titleMessage={sharedMessages.auction}
        />
      )}
      {isSocialGamesEnabled && (
        <DropdownListItem
          to={linkToSocialGamesBase}
          Icon={SocialGamesIcon}
          data-testid="socialGames"
          onClick={reportGoSocialGamesUiAction}
          titleMessage={sharedMessages.socialGames}
        />
      )}

      <Typography
        type={TYPOGRAPHY_TYPE.PARAGRAPH3}
        className={styles.menuCategoryTitle}
        as="div"
      >
        <FormattedMessage {...sharedMessages.settings} />
      </Typography>
      {isNewSettingEnabled && isDesktop && (
        <DropdownListItem
          to={linkToProfileSettingsPage}
          Icon={SettingsIcon}
          data-testid="settings"
          titleMessage={sharedMessages.settings}
        />
      )}

      {!isDesktop && (
        <>
          <DropdownListItem
            to={linkToAccountSettingsPage}
            Icon={UserIcon}
            data-testid="account-settings"
            titleMessage={sharedMessages.account}
          />
          <DropdownListItem
            to={makeLinkToSettingsScreen(SETTINGS_SCREEN_PAYMENT)}
            Icon={PaymentSettingsIcon}
            data-testid="payment-settings"
            titleMessage={sharedMessages.payments}
          />
          <DropdownListItem
            to={linkToPrivacySettingsPage}
            Icon={LockIcon}
            data-testid="privacy-settings"
            titleMessage={sharedMessages.privacy}
          />
          <DropdownListItem
            to={linkToGeneralSettingsPage}
            Icon={SettingsIcon}
            data-testid="genaral-settings"
            titleMessage={sharedMessages.general}
          />
          <ExploreNavigationDarkModeMenuItem />
        </>
      )}

      {isDesktop && <ExploreNavigationDarkModeMenuItem />}

      <Typography
        type={TYPOGRAPHY_TYPE.PARAGRAPH3}
        className={styles.menuCategoryTitle}
        as="div"
      >
        <FormattedMessage {...sharedMessages.creatorTools} />
      </Typography>
      {!isBurgerMenu && <RedeemsMenuItem />}
      {isGifterStatisticsEnabled && (
        <DropdownListItem
          to={linkToGifterStatistics}
          Icon={GifterStatisticsIcon}
          data-testid="statistics"
          titleMessage={sharedMessages.gifterStatistics}
        />
      )}
      {isSubscriptionsEnabled && isSubscribeMenuItemEnabled && (
        <>
          <FanZoneItem />
          <MyFansItem />
        </>
      )}
      <>
        <Typography
          type={TYPOGRAPHY_TYPE.PARAGRAPH3}
          className={styles.menuCategoryTitle}
          as="div"
        >
          <FormattedMessage {...sharedMessages.serviceInfo} />
        </Typography>
        <LegalLinks />
      </>
      {!isNewSettingEnabled && (
        <DropdownListItem
          to={makeLinkToSettingsScreen(SETTINGS_SCREEN_APP)}
          Icon={SettingsIcon}
          data-testid="app-settings"
          titleMessage={sharedMessages.appSettings}
        />
      )}
      {!isRedesignedReferralProgramEnabled && (
        <DropdownListItem
          Icon={ReferralIcon}
          titleMessage={sharedMessages.referralProgram}
          data-testid="virality-button"
          to={{
            pathname: linkToReferralProgram,
            state: { source: "banner_profile" },
          }}
        />
      )}
    </>
  );
};

export default memo(AuthorizedUserItems);
