import React, { FC, memo, useCallback, useContext } from "react";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useIsVisitorProfile } from "src/features/profile/visitorProfile/exports/hooks";
import { RootState } from "state/delegate";
import {
  profilesCacheSelectors,
  visitorProfileSelectors,
} from "state/selectors";
import { formatDisplayName } from "ui/common/DisplayName";
import EmojiContext from "ui/common/emoji/EmojiContext";

interface UsernameTitleProps {
  accountId: string;
}

const UsernameTitle: FC<UsernameTitleProps> = ({ accountId }) => {
  const intl = useIntl();
  const { removeEmojisIfUnsupported } = useContext(EmojiContext);
  const isVisitorProfilePage = useIsVisitorProfile();
  const basicProfile = useSelector(
    useCallback(
      (state: RootState) =>
        profilesCacheSelectors.getBasicProfile(state, accountId),
      [accountId]
    )
  );

  const visitorProfile = useSelector(visitorProfileSelectors.getVisitorProfile);

  const profileInfo = isVisitorProfilePage
    ? visitorProfile?.basic
    : basicProfile;

  return profileInfo ? (
    <Helmet
      title={formatDisplayName({
        intl,
        basicProfile: profileInfo,
        postProcessName: removeEmojisIfUnsupported,
      })}
    />
  ) : null;
};

export default memo(UsernameTitle);
