import React, { memo } from "react";
import { FormattedMessage } from "react-intl";
import { GooglePlayLink } from "ui/common/storeLink/StoreLink";
import styles from "./Footer.scss";

const Footer: React.FC = () => (
  <div className={styles.root}>
    <FormattedMessage id="get-tango-app" defaultMessage="Get the Tango App" />
    <div className={styles.apps}>
      <GooglePlayLink className={styles.app} useDarkIcon />
    </div>
  </div>
);

export default memo(Footer);
