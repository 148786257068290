import React, { FC, memo, useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import classnames from "classnames";
import Button, { ButtonSize, ButtonVariant } from "ui/common/button/Button";
import InputUtils from "utils/InputUtils";
import { ReactComponent as Coin } from "img/ic_coin.svg";
import { ReactComponent as MinusIcon } from "img/nft/ic_minus.svg";
import { ReactComponent as PlusIcon } from "img/nft/ic_plus.svg";
import styles from "./Range.scss";

interface RangeProps {
  compactLayout?: "horizontal" | "vertical";
  initialValue: number;
  isCompact?: boolean;
  isShader?: boolean;
  onValueChange: (value: number) => void;
  valueStep: number;
}

const MAX_VALUE = 999999999;
const MIN_VALUE = 1;
const MINUS = "minus";
const PLUS = "plus";

const clamp = (value: number) =>
  Math.min(Math.max(value, MIN_VALUE), MAX_VALUE);

const Range: FC<RangeProps> = ({
  initialValue,
  isCompact,
  compactLayout = "vertical",
  onValueChange,
  isShader = false,
  valueStep,
}) => {
  const { formatNumber } = useIntl();
  const [value, setValue] = useState(initialValue);

  const isMinusButtonDisabled = value <= MIN_VALUE;
  const isPlusButtonDisabled = value >= MAX_VALUE;

  const onChange = useCallback(
    (e) => setValue(clamp(InputUtils.getNumberValue(e.currentTarget.value))),
    []
  );

  const onValueShift = useCallback(
    (e) => {
      const modifier = e.currentTarget.dataset.modifier === MINUS ? -1 : 1;
      setValue((value) => clamp(modifier * valueStep + value));
    },
    [valueStep]
  );

  useEffect(() => onValueChange(value), [onValueChange, value]);

  const variant = isShader
    ? ButtonVariant.SECONDARY_ON_SHADER
    : ButtonVariant.SECONDARY;

  const size = isCompact
    ? ButtonSize.CIRCLE_SMALL_32
    : ButtonSize.CIRCLE_BIG_48;

  return (
    <div
      className={classnames(
        styles.root,
        {
          [styles.compact]: isCompact,
          [styles.isShader]: isShader,
        },
        styles[compactLayout]
      )}
    >
      <div className={styles.inputContainer}>
        <Button
          className={styles.button}
          size={size}
          variant={variant}
          data-modifier={MINUS}
          onClick={onValueShift}
          disabled={isMinusButtonDisabled}
          data-testid="minus-button"
        >
          <MinusIcon />
        </Button>
        <label className={styles.input} data-testid="range-input">
          <Coin className={styles.coin} />
          <div className={styles.container}>
            <span className={styles.fakeInput}>{formatNumber(+value)}</span>
            <input
              onChange={onChange}
              value={formatNumber(+value)}
              className={styles.nativeInput}
            />
          </div>
        </label>
        <Button
          className={styles.button}
          size={size}
          variant={variant}
          data-modifier={PLUS}
          onClick={onValueShift}
          disabled={isPlusButtonDisabled}
          data-testid="plus-button"
        >
          <PlusIcon />
        </Button>
      </div>
    </div>
  );
};

export default memo(Range);
