import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import PropTypes from "prop-types";
import { BrowserType, DeviceType } from "src/enums";
import loadCollectedGifts from "state/flows/loadCollectedGifts";
import {
  customDrawerSelectors,
  deviceInfoSelectors,
  giftingRequestsSelectors,
  giftsCacheSelectors,
  loginSelectors,
} from "state/selectors";
import { actionCreators as giftsCacheActionCreators } from "state/tree/giftsCache";
import { themeLockClassnames } from "ui/Theme";
import Scrollbar, { ScrollbarVariant } from "ui/common/scrollbar/Scrollbar";
import { useCountRenderingGift } from "ui/hooks/useCountRenderingGift";
import useRefreshGiftsDrawer from "ui/hooks/useRefreshGiftsDrawer";
import useScrollableTab from "ui/hooks/useScrollableTab";
import Category from "./Category";
import GiftRendererContext from "./GiftRendererContext";
import GiftTabs from "./GiftTabs";
import styles from "./GiftsDrawer.scss";

const categoriesListSelector = (state) => {
  const customCategories = customDrawerSelectors.getCategories(state);
  const defaultCategories = giftsCacheSelectors.getCategoryIdsList(state);
  const isLoggedIn = loginSelectors.isLoggedIn(state);
  if (!customCategories?.length || !isLoggedIn) {
    return defaultCategories;
  }

  const categories = customCategories
    .map((customCat) =>
      defaultCategories.includes(customCat.id) ? customCat.id : null
    )
    .filter((cat) => !!cat);

  return !categories.length ? defaultCategories : categories;
};

const getScrollIntoViewOptions = ({ browserName, isAnyMobileDevice }) => {
  const isSafari = browserName === BrowserType.SAFARI;
  const isDesktopSafari = isSafari && !isAnyMobileDevice;

  if (isDesktopSafari) {
    return { behavior: "smooth", block: "end" };
  }

  return null;
};

const COUNT_VISIBLE_TABS = 6;
const emptyArray = [];

const selectorFactory = (state) => ({
  browserName: deviceInfoSelectors.getBrowserName(state),
  deviceType: deviceInfoSelectors.getDeviceType(state),
  isAnyMobileDevice: deviceInfoSelectors.isAnyMobileDevice(state),
});

const GiftsDrawer = ({
  className,
  numberOfGiftsPerRow,
  selectedTabId,
  onGiftClick,
}) => {
  const containerRef = useRef();
  const tabsRef = useRef([]);
  const categoriesRef = useRef([]);
  const { deviceType, browserName, isAnyMobileDevice } = useSelector(
    selectorFactory,
    shallowEqual
  );
  const isIos = deviceType === DeviceType.IOS || deviceType === DeviceType.IPAD;
  useRefreshGiftsDrawer();

  /* form categories list */
  const customCategoryIdsList =
    useSelector(categoriesListSelector, shallowEqual) || emptyArray;
  /**/

  const collectibleGifts =
    useSelector(giftsCacheSelectors.getCollectibleGiftIds) || emptyArray;
  const dispatch = useDispatch();
  const [selectedCategoryId, setSelectedCategoryId] = useState(
    selectedTabId || customCategoryIdsList[0]
  );

  const targetCollectionId = useSelector(
    giftsCacheSelectors.getTargetCollectionId
  );

  const targetAccountId = useSelector(
    giftingRequestsSelectors.getRecipientAccountId
  );
  const { giftSetRenderPosition, giftGetRenderPosition } =
    useCountRenderingGift();

  const onShowTabContent = useCallback((target) => {
    const { category } = target.dataset;
    setSelectedCategoryId(category);

    const activeTab = tabsRef.current.find(
      (item) => item.dataset.tabcategory === category
    );

    if (activeTab) {
      const number = +activeTab.dataset.tabindex;
      const nextElement =
        number >= COUNT_VISIBLE_TABS
          ? activeTab.nextElementSibling
          : activeTab.previousElementSibling;

      if (nextElement) {
        nextElement.scrollIntoView(
          getScrollIntoViewOptions({ browserName, isAnyMobileDevice })
        );
      }
    }
  }, []);

  const { setContentTabs, setOptions } = useScrollableTab(onShowTabContent);

  useEffect(() => {
    setContentTabs(categoriesRef.current);
    setOptions({
      rootMargin: `-${containerRef.current?.clientHeight / 2}px 0px 0px 0px`,
    });
  }, []);

  useEffect(() => {
    if (selectedTabId) {
      setSelectedCategoryId(selectedTabId);
      dispatch(giftsCacheActionCreators.setGiftsDrawerTabId(false));
    }
  }, [selectedTabId]);
  useEffect(() => {
    if (!selectedCategoryId && customCategoryIdsList.length) {
      setSelectedCategoryId(customCategoryIdsList[0]);
    }
  }, [customCategoryIdsList, selectedCategoryId]);

  useEffect(() => {
    if (targetAccountId && collectibleGifts.length) {
      dispatch(loadCollectedGifts(targetAccountId));
    }
  }, [targetAccountId, collectibleGifts.length]);

  useEffect(() => {
    if (targetCollectionId) {
      const collection = document.querySelector(
        `[data-collection="${targetCollectionId}"]`
      );
      if (collection) {
        collection.scrollIntoView();
      }
      dispatch(giftsCacheActionCreators.setTargetCollectionId(null));
    }
  }, [targetCollectionId]);

  const numberOfGiftsPerRowStyle = useMemo(
    () => ({ "--number-of-gifts-per-row": numberOfGiftsPerRow }),
    [numberOfGiftsPerRow]
  );

  const renderGiftActions = useMemo(
    () => ({
      giftSetRenderPosition,
      giftGetRenderPosition,
    }),
    []
  );

  const scrollToGiftCategory = useCallback(
    (categoryId) => {
      const element = categoriesRef.current?.find(
        (item) => item.dataset.category === categoryId
      );

      const scrollElement = containerRef.current;
      if (element && scrollElement) {
        let prevOverflow;
        if (isIos) {
          prevOverflow = scrollElement.style.overflow;
          scrollElement.style.overflow = "hidden";
        }
        const offsetTopContainer =
          scrollElement.clientHeight - element.clientHeight;
        scrollElement.scroll({
          top:
            element.offsetTop -
            (offsetTopContainer > 0 ? offsetTopContainer : 0),
        });
        if (isIos) {
          scrollElement.style.overflow = prevOverflow;
        }
      }

      setSelectedCategoryId(categoryId);
    },
    [isIos]
  );

  return (
    <div
      data-testid="gifts"
      className={classnames(styles.root, themeLockClassnames.dark, className)}
    >
      <Scrollbar
        ref={containerRef}
        wrapperClassName={styles.scrollbar}
        variant={ScrollbarVariant.LIGHT}
      >
        <div style={numberOfGiftsPerRowStyle}>
          <GiftRendererContext.Provider value={renderGiftActions}>
            {customCategoryIdsList.map((categoryId) => (
              <Category
                ref={categoriesRef}
                key={categoryId}
                className={styles.category}
                categoryId={categoryId}
                onGiftClick={onGiftClick}
              />
            ))}
          </GiftRendererContext.Provider>
        </div>
      </Scrollbar>
      {customCategoryIdsList.length > 1 && (
        <GiftTabs
          ref={tabsRef}
          categoryIdsList={customCategoryIdsList}
          selectedCategoryId={selectedCategoryId}
          selectCategory={scrollToGiftCategory}
        />
      )}
    </div>
  );
};

GiftsDrawer.propTypes = {
  onGiftClick: PropTypes.func,
  numberOfGiftsPerRow: PropTypes.number.isRequired,
  className: PropTypes.string,
  selectedTabId: PropTypes.string,
};

export default GiftsDrawer;
