import React from "react";
import { useSelector } from "react-redux";

import { loginSelectors } from "state/selectors";
import AuthorizedUserItems from "ui/common/dropdownMenu/menuList/components/AuthorizedUserItems";
import Scrollbar from "ui/common/scrollbar/Scrollbar";

interface MenuListProps {
  isBurgerMenu: boolean;
}

const AuthorizedExploreNavigationItems: React.FC<MenuListProps> = ({
  isBurgerMenu,
}) => {
  const isLoggedIn = useSelector(loginSelectors.isLoggedIn);

  if (!isLoggedIn) {
    return null;
  }

  return (
    <Scrollbar>
      <AuthorizedUserItems isBurgerMenu={isBurgerMenu} />
    </Scrollbar>
  );
};

export default AuthorizedExploreNavigationItems;
