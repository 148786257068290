import React, {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import classnames from "classnames";
import { previewUrl } from "api/playlist";
import { Nullable } from "src/types/common";
import { StreamStatus } from "src/types/richFragment/Stream";
import { useUnmount } from "src/utils/miniReactUse";
import { getStreamsAlternativeDomainContentSupportEnabled } from "state/abTests";
import { TileProps } from "ui/common/streamTiles/components/types";
import { useMakeAlternativeDomainUrl } from "ui/hooks/useMakeAlternativeDomainUrl";
import Player from "ui/player";
import { useVideoEnabledForStream } from "ui/scenes/stream/common/useVideoEnabled";
import { ReactComponent as PlayIcon } from "img/ic_play_on_tile.svg";
import { ReactComponent as ReplayIcon } from "img/ic_replay.svg";
import styles from "ui/common/streamTiles/components/cover/components/Dimmer.scss";

interface DimmerProps extends Pick<TileProps, "stream"> {
  className?: string;
  isHovered: boolean;
  isMobilePreviewAvailable: boolean;
}

const Dimmer = forwardRef<HTMLDivElement, DimmerProps>(
  (
    {
      className,
      isHovered,
      isMobilePreviewAvailable,
      stream: { status, previewListUrl, liveListUrl, playlistUrl, id },
    },
    rootRef
  ) => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const timeoutRef = useRef<Nullable<number>>(null);
    const isVideoEnabled = useVideoEnabledForStream(id);
    const [isReady, setIsReady] = useState(false);
    const isPlayerShown =
      (isHovered || isMobilePreviewAvailable) && isVideoEnabled;
    const makeAlternativeDomainUrl = useMakeAlternativeDomainUrl(
      getStreamsAlternativeDomainContentSupportEnabled
    );

    const handlePlaying = useCallback(() => {
      if (!isReady && !timeoutRef.current) {
        timeoutRef.current = window.setTimeout(() => setIsReady(true), 300);
      }
    }, [isReady]);

    useEffect(() => {
      if (
        isReady &&
        ((!isHovered && !isMobilePreviewAvailable) || !isVideoEnabled)
      ) {
        setIsReady(false);
        timeoutRef.current = null;
      }
    }, [isHovered, isMobilePreviewAvailable, isVideoEnabled, isReady]);

    useUnmount(() => {
      if (timeoutRef.current) {
        window.clearTimeout(timeoutRef.current);
      }
    });

    return (
      <div
        ref={rootRef}
        className={classnames(styles.root, className, {
          [styles.isMobilePreviewAvailable]: isMobilePreviewAvailable,
        })}
      >
        {isPlayerShown && (
          <Player
            ref={videoRef}
            // @ts-ignore
            src={makeAlternativeDomainUrl(
              previewListUrl || liveListUrl || playlistUrl || previewUrl(id)
            )}
            className={classnames(styles.preview, isReady && styles.visible)}
            onPlaying={handlePlaying}
            forceDisableHd
            muted
          />
        )}

        {status === StreamStatus.TERMINATED ? (
          <ReplayIcon className={classnames(styles.icon, styles.replay)} />
        ) : (
          <PlayIcon className={styles.icon} />
        )}
      </div>
    );
  }
);

export default memo(Dimmer);
