import { ComponentType, Ref, SVGProps } from "react";
import { HTMLButtonProps } from "src/types/common";
import { LinkPropsWithInnerRef } from "src/utils/isNonLinkProps";

export enum ButtonSize {
  BIG_48 = "big",
  CIRCLE_BIG_48 = "circleBig",
  CIRCLE_EXTRABIG_56 = "circleExtraBig",
  CIRCLE_EXTRASMALL_24 = "circleExtraSmall",
  CIRCLE_MEDIUM_40 = "circleMedium",
  CIRCLE_SMALL_32 = "circleSmall",
  EXTRABIG_64 = "extraBig",
  EXTRASMALL_16 = "extraSmall",
  MEDIUM_32 = "medium",
  SMALL_24 = "small",
}

export enum ButtonVariant {
  ANCHOR = "anchor",
  ANCHOR_ON_SHADER = "anchorOnShader",
  GET_COINS = "getCoins",
  GET_COINS_SECONDARY = "getCoinsSecondary",
  GET_COINS_WITH_ICON = "getCoinsWithIcon",
  ICON_ONLY = "iconOnly",
  PRIMARY = "primary",
  PRIMARY_ACCENT = "primaryAccent",
  PRIMARY_ON_SHADER = "primaryOnShader",
  SECONDARY = "secondary",
  SECONDARY_ON_SHADER = "secondaryOnShader",
  SUBSCRIPTION_PRIMARY = "subscriptionPrimary",
  SUBSCRIPTION_PRIMARY_ON_SHADER = "subscriptionPrimaryOnShader",
  SUBSCRIPTION_SECONDARY = "subscriptionSecondary",
  SUBSCRIPTION_SECONDARY_ON_SHADER = "subscriptionSecondaryOnShader",
  SYSTEM_DARK = "systemDark",
  SYSTEM_LIGHT = "systemLight",
  TERTIARY = "tertiary",
  TERTIARY_ON_SHADER = "tertiaryOnShader",
}

interface BaseButtonProps {
  active?: boolean;
  leftIcon?: ComponentType<SVGProps<SVGSVGElement>>;
  rightIcon?: ComponentType<SVGProps<SVGSVGElement>>;
  size: ButtonSize;
  variant: ButtonVariant;
}

interface IconOnlyVariants {
  active?: never;
  leftIcon?: never;
  rightIcon?: never;
  size:
    | ButtonSize.BIG_48
    | ButtonSize.EXTRASMALL_16
    | ButtonSize.MEDIUM_32
    | ButtonSize.SMALL_24;
  variant: ButtonVariant.ICON_ONLY;
}

interface FlatVariants {
  active?: boolean;
  leftIcon?: ComponentType<SVGProps<SVGSVGElement>>;
  rightIcon?: ComponentType<SVGProps<SVGSVGElement>>;
  size:
    | ButtonSize.BIG_48
    | ButtonSize.EXTRABIG_64
    | ButtonSize.MEDIUM_32
    | ButtonSize.SMALL_24;
  variant: Exclude<ButtonVariant, ButtonVariant.ICON_ONLY>;
}

interface CircleVariants {
  active?: boolean;
  leftIcon?: never;
  rightIcon?: never;
  size:
    | ButtonSize.CIRCLE_BIG_48
    | ButtonSize.CIRCLE_EXTRABIG_56
    | ButtonSize.CIRCLE_EXTRASMALL_24
    | ButtonSize.CIRCLE_MEDIUM_40
    | ButtonSize.CIRCLE_SMALL_32;
  variant: Exclude<ButtonVariant, ButtonVariant.ICON_ONLY>;
}

export type ButtonVariantsProps =
  | BaseButtonProps
  | CircleVariants
  | FlatVariants
  | IconOnlyVariants;

interface ButtonElementProps extends HTMLButtonProps {
  innerRef?: Ref<HTMLButtonElement>;
  to?: undefined;
}

export type ButtonProps = {
  dataTestId?: string;
  disabled?: boolean;
  external?: boolean;
  isSingleLineDisabled?: boolean;
  uppercase?: boolean;
} & (ButtonElementProps | LinkPropsWithInnerRef) &
  ButtonVariantsProps;
