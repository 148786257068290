import React, { ChangeEvent } from "react";
import classnames from "classnames";
import { ComponentWithClassName } from "src/types/common";
import styles from "./InputSlider.scss";

interface InputSlider {
  disabled?: boolean;
  max?: number;
  min?: number;
  name?: string;
  onChange: (value: number) => void;
  step?: number;
  value: number;
}

export const InputSlider: ComponentWithClassName<InputSlider> = ({
  name,
  value,
  onChange,
  min = 0,
  max = 1,
  step = 0.01,
  disabled = false,
  className,
}) => {
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(+e.target.value);
  };

  const backgroundSizePosition = ((value - min) / (max - min)) * 100;

  return (
    <div className={classnames(styles.sliderContainer, className)}>
      <input
        type="range"
        className={styles.slider}
        style={{
          backgroundSize: `${backgroundSizePosition}% 100%`,
        }}
        name={name}
        min={min}
        max={max}
        value={value}
        onChange={handleInputChange}
        step={step}
        disabled={disabled}
      />
    </div>
  );
};
