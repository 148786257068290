import React, { useCallback, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { shallowEqual, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import { emitEvent } from "@analytics/emit";
import { EventFields, EventNames } from "@analytics/enums";
import { ScreenViewReporter } from "@analytics/screenView/ScreenViewReportingContext";
import { sendGiftFromBc } from "api/gifts";
import { getIsSendGiftBIv2Enabled } from "src/features/gifts/giftsSoc";
import { GiftCurrency } from "src/state/enums/giftCurrencyType";
import { getGiftsAlternativeDomainContentSupportEnabled } from "state/abTests";
import {
  giftsCacheSelectors,
  settingsSelectors,
  viewerSessionSelectors,
} from "state/selectors";
import Button, { ButtonSize, ButtonVariant } from "ui/common/button/Button";
import sharedMessages from "ui/common/intl/sharedMessages";
import { useMakeAlternativeDomainUrl } from "ui/hooks/useMakeAlternativeDomainUrl";
import useUiAction from "ui/hooks/useUiAction";
import styles from "./GiftFromBroadcaster.scss";

const selector = (state) => ({
  isSendGiftBIv2Enabled: getIsSendGiftBIv2Enabled(state),
  streamId: viewerSessionSelectors.getStreamId(state),
  isOneClickGiftingEnabled: settingsSelectors.isOneClickGiftingEnabled(state),
  isGiftBoughtWithCoins: giftsCacheSelectors.isBuyGiftWithCoins(state),
});

const GiftFromBroadcaster = ({
  receiverId,
  giftId,
  message: defaultMessage,
  dismiss,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();
  const [message, setMessage] = useState(defaultMessage);
  const [isEdited, setIsEdited] = useState(false);
  const gift = useSelector((state) =>
    giftsCacheSelectors.getGiftById(state, giftId)
  );

  const {
    isSendGiftBIv2Enabled,
    streamId,
    isOneClickGiftingEnabled,
    isGiftBoughtWithCoins,
  } = useSelector(selector, shallowEqual);

  const isBoughtWithCoins = isGiftBoughtWithCoins || !gift.withdrawInPoint;

  const giftPrice = isBoughtWithCoins
    ? gift.priceInCredit
    : gift.withdrawInPoint;

  const giftCurrency = isBoughtWithCoins
    ? GiftCurrency.COINS
    : GiftCurrency.DIAMONDS;

  const editActionParams = useMemo(
    () => ({
      [EventFields.PEER_ID]: receiverId,
      [EventFields.STREAM_ID]: streamId,
    }),
    [receiverId, streamId]
  );
  const sendActionParams = useMemo(
    () => ({
      [EventFields.PEER_ID]: receiverId,
      [EventFields.ITEM_TYPE]: isEdited ? "with_changes" : "without_changes",
      [EventFields.STREAM_ID]: streamId,
    }),
    [isEdited, streamId]
  );
  const useEditUiAction = useUiAction({
    target: "edit_message",
    additionalParams: editActionParams,
  });
  const onChange = useCallback(
    (event) => {
      !isEdited && setIsEdited(true);
      setMessage(event.target.value);
    },
    [isEdited]
  );
  const onSendClick = useCallback(() => {
    const trackingId = uuidv4();

    if (isSendGiftBIv2Enabled) {
      emitEvent(EventNames.SEND_GIFT_V2, {
        [EventFields.PEER_ID]: receiverId,
        [EventFields.GIFT_ID]: giftId,
        [EventFields.GIFT_SOURCE]: 9,
        [EventFields.GIFT_SOURCE_ID]: streamId,
        [EventFields.ONE_CLICK_PURCHASE]: 0,
        [EventFields.IS_ONE_CLICK_GIFT]: isOneClickGiftingEnabled,
        [EventFields.IS_COMBO_GIFT]: false,
        [EventFields.TRACKING_ID]: trackingId,
        [EventFields.GIFT_CURRENCY]: giftCurrency,
        [EventFields.GIFT_PRICE]: giftPrice,
      });
    }
    emitEvent(EventNames.SEND_GIFT, {
      [EventFields.PEER_ID]: receiverId,
      [EventFields.STREAM_ID]: streamId,
      [EventFields.GIFT_ID]: giftId,
      [EventFields.SOURCE]: 9,
      [EventFields.ONE_CLICK_PURCHASE]: 0,
    });
    sendGiftFromBc({
      giftId,
      accountId: receiverId,
      message,
    }).then(() => {
      enqueueSnackbar(
        intl.formatMessage(sharedMessages.giftFromBroadcasterSent),
        {
          autoHideDuration: 1500,
          variant: "success",
        }
      );
      dismiss();
    });
  }, [message]);
  const onSendClickUiAction = useUiAction({
    target: "send_message",
    callback: onSendClick,
    additionalParams: sendActionParams,
  });

  const makeAlternativeDomainUrl = useMakeAlternativeDomainUrl(
    getGiftsAlternativeDomainContentSupportEnabled
  );

  return (
    <>
      <ScreenViewReporter
        name="gift_from_bc"
        additionalParams={{
          [EventFields.PEER_ID]: receiverId,
          [EventFields.STREAM_ID]: streamId,
        }}
      />
      <div className={styles.info}>
        <FormattedMessage
          id="gift.from.bc.invite"
          defaultMessage="Send a FREE GIFT to invite"
        />
      </div>
      {gift && (
        <img
          src={makeAlternativeDomainUrl(gift.icon)}
          className={styles.giftIcon}
        />
      )}
      <div className={styles.messageWrapper}>
        <span className={styles.name}>
          <FormattedMessage
            id="chat.current-conversation.input-placeholder"
            defaultMessage="Message"
          />
        </span>
        <textarea
          value={message}
          className={styles.message}
          onClick={useEditUiAction}
          onChange={onChange}
        />
      </div>
      <Button
        size={ButtonSize.BIG_48}
        variant={ButtonVariant.PRIMARY}
        onClick={onSendClickUiAction}
        className={styles.button}
      >
        <FormattedMessage
          id="modal.gift-confirmation.cta"
          defaultMessage="Send"
        />
      </Button>
    </>
  );
};
GiftFromBroadcaster.propTypes = {
  receiverId: PropTypes.string.isRequired,
  giftId: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  dismiss: PropTypes.func.isRequired,
};
export default GiftFromBroadcaster;
